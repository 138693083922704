<template>
    <div>
        <base-header base-title="Report Pendapatan Referral"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.month') }} :</label>
                                        <div class="col-md-8">
                                            <month-select
                                                :changedValue.sync="month"
                                                :id-select-picker="'selectMonth'"
                                                :selected-picker="month"
                                                :value-num="true"
                                                :is-validate="true"
                                            ></month-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                   <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.year') }} :</label>
                                        <div class="col-md-8">
                                            <year-select
                                                :changedValue.sync="year"
                                                :id-select-picker="'selectYear'"
                                                :selected-picker="year"
                                                :is-validate="true"
                                            ></year-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <button
                                            type="button"
                                            @click="submitSearch()"
                                            class="btn btn-sm btn-default btn-bold btn-upper mt-1 ml-2 "
                                            >
                                            <i class="flaticon2-search"></i> {{ $t('button.search') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                                <div id="btn_export_container">
                                </div>
                        </div>


                    </div>
                    <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <data-tables :table="table"></data-tables>
                        <div class="rumus-week" style="fontSize:80%; marginTop:0.25rem; widht:100%; fontWeight:400;">
                            <span>Keterangan :</span>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-3"><sup>(2)</sup> W1</div>
                                        <div class="col-1">:</div>
                                        <div class="col-auto">Tanggal 1 - 8</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3"><sup>(3)</sup> W2</div>
                                        <div class="col-1">:</div>
                                        <div class="col-auto">Tanggal 9 - 16</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3"><sup>(4)</sup> W3</div>
                                        <div class="col-1">:</div>
                                        <div class="col-auto">Tanggal 17 - 23</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3"><sup>(5)</sup> W4</div>
                                        <div class="col-1">:</div>
                                        <div class="col-auto">Tanggal 24 - {{ lastDay }} </div>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                   <span><sup>(1)</sup> Perujuk yang ditampilkan adalah perujuk yang memiliki transaksi dari awal bulan pada tahun yang dipilih sampai dengan bulan dan tahun yang dipilih.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../../_base/BaseTitle";
import BaseHeader from "./../../_base/BaseHeader";
import DateRangePicker from "./../../_general/DateRangePicker";
import DaftarPerujuk from "./../../../constants/report-daftar-perujuk";
import DataTables from "./../../_table/DataTables";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
import MonthSelect from "./../../_select/Month";
import YearSelect from "./../../_select/Year";
import JejakEnum from "./../../../constants/jejak-enum";
const ReportRepository = RepositoryFactory.get("report");
export default {
    components: {
        BaseHeader,
        BaseTitle,
        DateRangePicker,
        DataTables,
        MonthSelect,
        YearSelect
    },
    data() {
        var vx = this;
        return {
            headTitle: "Report Pendapatan Referral",
            month: new Date().getMonth()  + 1,
            year: new Date().getFullYear(),
            dateStart: null,
            dateEnd: null,
            weeks: [],
            showLoading:false,
            isLoaded:false,
            jejakEnum: JejakEnum,
            tableName: "table_detail_transaksi",
            mainTitle: "Laporan Detail Transaksi",
            subTitle: "",
            table: {
                tableName: "table_detail_transaksi",
                dateFormat: "dd-M-yyyy",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: " ",
                        targets: "_all"
                    },
                    {
                        targets: 0,
                        render: (data, type, row, meta) => {
                            return meta.row + 1;
                        },
                        searchable: false,
                    },
                    {
                        targets: 2,
                        visible: true,
                        render: (data, type, row, meta) => {
                            return data.length > 0 ? data.map(s => s.nama).join(' - ') : ' ';
                        }
                    },
                    {
                        targets: [3,10],
                        visible: true,
                        render: (data, type, row, meta) => {
                           return (data == 0) ? ' ' : data;
                        }
                    },
                    {
                        targets: [4,5,6,7,8,11],
                        className: 'text-right',
                        render: (data, type, row, meta) => {
                            return (data !== 0) ? vx.convertSeperator(data) : ' ';

                        }
                    },
                    {
                        targets: 13,
                        visible: false
                    },
                ],
                buttons:[
                    {
                        extend: 'excelHtml5',
                        title: "Laporan Detail Transaksi",
                        messageTop: vx.subTitle,
                        autoFilter: false,
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12],
                            format: {
                                header(data, column){
                                    return data.replace(/(<([^>]+)>).[0-9].(<([^>]+)>)/g, '') 
                                },
                                body(data, row, column, node) {
                                    return [4, 5, 6, 7, 8, 11].includes(column) ? data.replace(/\./g, '').replace(',', '.') : data;
                                }
                            }
                        },
                    },
                    {
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        pageSize: 'A4',
                        title: 'Laporan Detail Transaksi',
                        exportOptions: {
                            columns: ':visible',
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12]
                        },
                        customize: function ( doc ) {
                            doc.content.splice( 1, 0,
                                {
                                    text: [
                                        {text : "Periode : ", bold: true},
                                        {text : vx.month.text + " " + vx.year +" \n"},
                                    ],
                                    alignment: 'left'
                                }
                            );
                        }
                    },
                    {
                        extend: 'print',
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12]
                        },
                        title: 'Laporan Detail Transaksi',
                        header: true,
                        footer: true,
                        text: 'Print Laporan',
                        titleAttr: 'Print',
                        customize: win => {
                           $(win.document.body)
                            .find('h1')
                            .after(
                                `
                                    <div class="row mt-5 mb-3 mx-0 px-0">
                                        <div class="col-2 text-left">
                                            <div class="row"><h5>Periode</h5></div>

                                        </div>
                                        <div class="col-5 text-left">
                                            <div class="row"><h5>: ${vx.month.text} ${vx.year}</h5></div>
                                        </div>
                                    </div>`
                            );
                            $(win.document.body).css('font-size', '12pt');
                            $(win.document.body)
                                .find('table')
                                .addClass('compact')
                                .css('font-size', 'inherit');
                            $(win.document.body)
                                .find('h1')
                                .addClass('text-center mt-3 mb-5');
                            const css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    }
                ],
                ordering:false,
                tableColumns: [
                    {
                        title: 'NO.',
                        data: 'id'
                    },
                    {
                        title: 'Nama Perujuk<sup>(1)</sup>',
                        data: 'name'
                    },
                    {
                        title: 'Nama Faskes/Dokter/Bidan',
                        data: 'faskes'
                    },
                    {
                        title: 'Total Visit Monthly',
                        data: 'TotalVisitMontly'
                    },
                    {
                        title: 'W1\n<sup>(2)</sup>',
                        data: 'weeks0'
                    },
                    {
                        title: 'W2\n<sup>(3)</sup>',
                        data: 'weeks1'
                    },
                    {
                        title: 'W3\n<sup>(4)</sup>' ,
                        data: 'weeks2'
                    },
                    {
                        title: 'W4\n<sup>(5)</sup>',
                        data: 'weeks3'
                    },
                    {
                        title: 'Total Monthly (IDR)',
                        data: 'TotalMonthly'
                    },
                    {
                        title: 'Rank Monthly',
                        data: 'rankMontly'
                    },
                    {
                        title: 'Total Visit Yearly',
                        data: 'TotalVisitYearly'
                    },
                    {
                        title: 'Total Yearly (IDR)',
                        data: 'TotalYearly'
                    },
                    {
                        title: 'Rank Yearly',
                        data: 'rankYearly'
                    },
                    {
                        title:"Actions",
                        data:"id"
                    }
                ],
                tableFilter: [
                    {
                        title: 'Nama Perujuk',
                        type: 'text'
                    },
                    {
                        title: 'Nama Faskes/Dokter/Bidan',
                        type: 'text'
                    },
                    {
                        title: 'Rank Monthly',
                        type: 'text'
                    },
                    {
                        title: 'Rank Yearly',
                        type: 'text'
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            },
            lastDay: 31
        }
    },
    provide() {
        return {
            $validator: this.$validator
        };
    },
    computed: {
        fullName: function () {
            const firstNama = (this.perujuk.firstName) ? this.perujuk.firstName : '';
            const lastNama = (this.perujuk.lastName) ? this.perujuk.lastName : '';
            return firstNama + ' ' + lastNama;
        }
    },
    methods: {
        ranking: function(dataTable) {
            // Rangking Monthly
            dataTable.sort( (a, b) => b.TotalMonthly - a.TotalMonthly );
            let rankMontly = 1;
            for (let i = 0; i < dataTable.length; i++) {
                if (i > 0 && dataTable[i].TotalMonthly < dataTable[i - 1].TotalMonthly) {
                    rankMontly++;
                }
                dataTable[i].rankMontly = rankMontly;
            }
            // Rangking Yearly
            dataTable.sort((a, b) => b.TotalYearly - a.TotalYearly);
            let rankYearly = 1;
            for (let i = 0; i < dataTable.length; i++) {
                if (i > 0 && dataTable[i].TotalYearly < dataTable[i - 1].TotalYearly) {
                    rankYearly++;
                }
                dataTable[i].rankYearly = rankYearly;
            }
            return dataTable;
        },
        checkDateWithinRange:function(index, date) {
            if (!this.weeks[index]) {
                return false;
            } else {
                const start = moment(this.weeks[index].start);
                const end = moment(this.weeks[index].end);
                const tanggal = moment(date);
                if (tanggal.isSameOrAfter(start) && end.isSameOrAfter(tanggal)) {
                    return true;
                } else {
                    return false;
                }
                // if (start <= tanggal && end >= tanggal) {
                //     return true;
                // } else {
                //     return false;
                // }
            }
        },
        convertSeperator: function(angka) {
            if (angka !== null) {
                const parts = angka.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return angka;
        },
        findTimeStamp: function(array, searchKey, searchValue) {
            const sortArray = array.sort((a,b) => moment(b.timestamp) - moment(a.timestamp));
            const index = sortArray.findIndex(x => x[searchKey] === searchValue);
            if (index >= 0) {
                return sortArray[index];
            } else {
                return null;
            }
        },
        formatDate: function (data , format = "DD-MMM-YYYY") {
            var momentDate = moment(data);
            return(momentDate.format(format));
        },
        submitSearch: function () {
            var vx = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    vx.generateWeeks();
                    vx.showLoading = true;
                    vx.isLoaded = false;
                    vx.subTitle = 'Periode : ' + vx.month.text + ' ' + vx.year;
                    vx.table.buttons[0].messageTop = vx.subTitle;
                    vx.fetch();
                }
            })
        },
        async fetch(resolve) {
                var params = {
                    jejaksStartDate: this.dateStart,
                    jejaksEndDate: this.dateEnd,
                    jejaksEnumStages: [this.jejakEnum.SUDAH_TERBAYARKAN],
                    jejakTerakhirStages: [this.jejakEnum.SUDAH_TERBAYARKAN, this.jejakEnum.CLOSED]
                };
                new Promise((resolve, reject) => {
                    ReportRepository.reportReferral(params, resolve, reject );
                })
                .then(response => {
                    const data = this.constructData(response.data);
                    if (this.isLoaded) {
                        var datatable = $("#" + this.tableName).DataTable();
                        datatable.clear();
                        datatable.rows.add(data);
                        datatable.draw("full-hold");
                        if(resolve !== undefined) {
                            resolve(data);
                        }
                    } else {
                        this.table.tableDataResult = data;
                        this.showLoading = false;
                        this.isLoaded = true;
                    }
                }).catch(error => {
                    var msg = (error.message) ? error.message : error;
                    this.showToastError('Detail Transaksi : ' + msg);
                })

        },
        constructData: function (transaksiReferral) {
            let temp = [];
            transaksiReferral.forEach(data => {
                const jejak = this.findTimeStamp(data.jejaks, 'enumStage', this.jejakEnum.SUDAH_TERBAYARKAN);
                const tanggal = moment(jejak.timestamp);
                const fee = data.fee ? data.fee : 0;
                let index = temp.findIndex(x => x.id === data.perujuk.id);
                const flag = tanggal.isSameOrBefore(moment(this.weeks[3].end));
                if (index < 0) {
                    let object = {};
                    const firstNama = data.perujuk.firstName ? data.perujuk.firstName : '';
                    const lastNama = data.perujuk.lastName ? data.perujuk.lastName : '';
                    const nama = firstNama + ' ' + lastNama;
                    if (flag) {
                        object.id = data.perujuk.id;
                        object.name = nama;
                        object.faskes = (data.perujuk.alamatPerujuks) ? data.perujuk.alamatPerujuks : [];
                        object.TotalVisitYearly = 1;
                        object.TotalYearly = fee;
                        for (let j = 0; j < 4; j++) {
                            object['weeks' + j] = this.checkDateWithinRange(j, tanggal) ? fee : 0;
                        }
                        if (
                            this.checkDateWithinRange(0, tanggal) ||
                            this.checkDateWithinRange(1, tanggal) ||
                            this.checkDateWithinRange(2, tanggal) ||
                            this.checkDateWithinRange(3, tanggal)
                        ) {
                            object.TotalVisitMontly = 1;
                            object.TotalMonthly = object.weeks0 + object.weeks1 + object.weeks2 + object.weeks3;
                        } else {
                            object.TotalVisitMontly = 0;
                            object.TotalMonthly = 0;
                        }
                        temp.push(object);
                    }
                } else {
                    if (flag) {
                        temp[index].TotalVisitYearly = temp[index].TotalVisitYearly + 1;
                        temp[index].TotalYearly = temp[index].TotalYearly + fee;
                        for (let j = 0; j < 4; j++) {
                            temp[index]['weeks' + j] = this.checkDateWithinRange(j, tanggal)
                                ? temp[index]['weeks' + j] + fee
                                : temp[index]['weeks' + j];
                        }
                        if (
                            this.checkDateWithinRange(0, tanggal) ||
                            this.checkDateWithinRange(1, tanggal) ||
                            this.checkDateWithinRange(2, tanggal) ||
                            this.checkDateWithinRange(3, tanggal)
                        ) {
                            temp[index].TotalVisitMontly = temp[index].TotalVisitMontly + 1;
                            temp[index].TotalMonthly = temp[index].weeks0 + temp[index].weeks1 + temp[index].weeks2 + temp[index].weeks3;
                        }
                    }
                }
            });
            temp = this.ranking(temp);
            return temp;
        }, 
        generateWeeks: function () {
            this.weeks = [];
            // Periode Of Month
            const firstDayOfMonth = moment(this.year + '-' + this.month + '-01');
            const lastDayOfMonth = moment(firstDayOfMonth).endOf('month');
            this.lastDay = lastDayOfMonth.format('DD')
            // week 1
            let week = {};
            week.start = firstDayOfMonth;
            week.end = moment(this.year + '-' + this.month + '-08');
            this.weeks.push(week);
            // week 2
            week = {};
            week.start = moment(this.year + '-' + this.month + '-09');
            week.end = moment(this.year + '-' + this.month + '-16');
            this.weeks.push(week);
            // week 3
            week = {};
            week.start = moment(this.year + '-' + this.month + '-17');
            week.end = moment(this.year + '-' + this.month + '-23');
            this.weeks.push(week);
            // week 4
            week = {};
            week.start = moment(this.year + '-' + this.month + '-24');
            week.end = lastDayOfMonth;
            this.weeks.push(week);
            // Periode Of Search
            this.dateStart = moment(this.year + '-01-01').format('YYYY-MM-DD');
            this.dateEnd = lastDayOfMonth.format('YYYY-MM-DD');
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {

    }
}
</script>
